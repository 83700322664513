import React from 'react';

const CognitoContext = React.createContext(null);

export const withCognito = Component => props => (
    <CognitoContext.Consumer>
        {cognito => <Component {...props} cognito={cognito} />}
    </CognitoContext.Consumer>
);

export default CognitoContext;