import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Typography, Space, message} from 'antd';
import treeData from "../Course/treeData";
import {DownOutlined} from "@ant-design/icons";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import {API, graphqlOperation} from 'aws-amplify';
import {getLessonContentTree} from '../../graphql/queries'
import {AuthUserContext} from "../Session";
import * as ROUTES from "../../constants/routes";
import {SignInButton} from '../Account/signin'

const {Title, Text} = Typography;

const Banner = () => {
        return (

            <div style={{
                // backgroundImage: 'url(https://gw.alipayobjects.com/zos/basement_prod/a8cf2dfe-ff6a-4a2e-ab73-f72dced0df99.svg)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative',
                height: '400px'
            }}>
                <Row justify={'center'} align="middle" style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }}>
                    <img alt="background" src="https://dropschema.s3.amazonaws.com/shapelined-background-resized.svg"
                         style={{width: '100%', height: '100%', objectFit: 'cover'}}>
                    </img>
                    <Row align="middle" gutter={[20, 0]} style={{
                        position: 'absolute',
                        color: 'white'
                    }}>
                        <Col xs={{span: 20, offset: 2}} sm={{span: 20, offset: 2}} md={{span: 16, offset: 2}}
                             lg={{span: 8, offset: 4}} xl={{span: 8, offset: 4}}>
                            <Space direction="vertical">
                                <Row>
                                    <Title level={1}>Become a <b>Job-Ready</b> Data Engineer</Title>
                                    <Text>Drop Schema is an interactive, web-based, online course, specializing in creating job-ready data engineers.</Text>
                                </Row>
                                <Row justify="center">
                                    <AuthUserContext.Consumer>
                                        {authUser =>
                                            authUser ? (
                                                <SignInButton route={ROUTES.COURSE} type={'primary'} text={'Getting Started'}/>
                                            ) : (
                                                <SignInButton route={ROUTES.SIGN_IN} type={'primary'} text={'Getting Started'}/>
                                            )
                                        }
                                    </AuthUserContext.Consumer>
                                </Row>
                            </Space>
                        </Col>
                    </Row>
                </Row>
            </div>
        )
    }
;
const Features = () => {

    return (
        <Row style={{minHeight: '450px', textAlign: 'center'}} justify={'space-around'} align={'middle'}>
            <Col span={16}>
                <Row justify={'space-around'}>
                    <Col xs={16} sm={16} md={5} lg={5} xl={5} style={{padding: '15px 0px 15px 0px'}}>
                        <Space direction="vertical">
                            <img alt='icon-cloud' src="https://dropschema.s3.amazonaws.com/icon-cloud.svg"
                                 style={{height: '120px'}}/>
                            <Title level={4}>Cloud-Focused</Title>

                            <Text><b>Start and stop cloud resources </b>throughout the course. Use AWS services like
                                EC2, RDS, Redshift, S3, and EMR.
                            </Text>
                        </Space>
                    </Col>
                    <Col xs={16} sm={16} md={5} lg={5} xl={5} style={{padding: '15px 0px 15px 0px'}}>
                        <Space direction="vertical">
                            <img alt='icon-academic-note'
                                 src="https://dropschema.s3.amazonaws.com/icon-academic-note.svg"
                                 style={{height: '120px', padding: '10px 0px 0px 0px'}}/>
                            <Title level={4}>Project-Based</Title>
                            <Text><b>Upload projects to be reviewed by a data engineer</b>. Work on projects while
                                completing lesson assignments.
                            </Text>
                        </Space>
                    </Col>
                    <Col xs={16} sm={16} md={5} lg={5} xl={5} style={{padding: '15px 0px 15px 0px'}}>
                        <Space direction="vertical">
                            <img alt='icon-laptop-upload'
                                 src="https://dropschema.s3.amazonaws.com/icon-laptop-upload.svg"
                                 style={{height: '120px'}}/>
                            <Title level={4}>Real World Problems</Title>
                            <Text>Focus on <b>common business problems</b> faced by tech companies. Learn common data
                                models and analytics techniques.
                            </Text>
                        </Space>
                    </Col>
                </Row>
            </Col>

        </Row>

    )
}

const Technologies = () => {
    return (
        <Row style={{minHeight: '250px', background: '#f6f6f4', textAlign: 'center'}} justify={'space-around'}
             align={'center'}>
            <Col xs={22} sm={22} md={22} lg={20} xl={20} style={{padding: '50px 0px 0px 0px'}}>
                <Title>
                    Technologies you will Learn
                </Title>
                <Row style={{padding: '30px 0px 10px'}} justify={'space-around'} align={'center'}>
                    <Col xs={20} sm={20} md={20} lg={10} xl={10}>
                        <img alt='something' width={'330px'} height={'340px'}
                             style={{margin: '-80px'}}
                             src={'https://eep.io/images/yzco4xsimv0y/53fiMloOjDzm36r16HZ7jQ/c1011cc73ef30dd06e60a9441c03019a/ILLO_MiniSpot_Person-holding-cards_copy.png?w=500&q=95'}/>
                    </Col>

                    <Col xs={8} sm={7} md={7} lg={4} xl={4}>

                        <Title level={2}>
                            AWS
                        </Title>
                        <Title level={3}>
                            Airflow
                        </Title>
                        <Title level={4}>
                            Hive
                        </Title>
                    </Col>
                    <Col xs={8} sm={7} md={7} lg={4} xl={4}>

                        <Title level={3}>
                            Snowflake
                        </Title>
                        <Title level={2}>
                            Python
                        </Title>
                        <Title level={3}>
                            Terraform <br/> Ansible
                        </Title>
                    </Col>
                    <Col xs={8} sm={7} md={7} lg={4} xl={4}>

                        <Title level={2}>
                            SQL
                        </Title>
                        <Title level={2}>
                            Spark
                        </Title>
                        <Title level={4}>
                            Postgres
                        </Title>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const Curriculum = () => {
    const [lessonTree, setLessonTree] = useState(null)

    useEffect(() => {
        fetchLessonTree()
    }, [])

    async function fetchLessonTree() {
        try {
            const lessonTree = await API.graphql(graphqlOperation(getLessonContentTree));
            console.log(lessonTree['data']['dropschema_lesson_content'])
            setLessonTree(lessonTree)
        } catch (err) {
            console.log('error fetching lessonTree');
            console.log(err)
        }
    }

    return (
        <Row justify={'space-around'} align={'center'}
             style={{background: '#f6f6f4', padding: '25px 0px 0px 0px'}}
        >
            <Col>
                <Row style={{padding: '0px 0px 25px 0px'}}>
                    <Col span={22} style={{textAlign: 'center'}}>
                        <Title level={2}>
                            Curriculum
                        </Title>
                        <Text>
                            * Subject To Change
                        </Text>
                    </Col>
                </Row>
                <Row justify={'space-around'} style={{padding: '0px 0px 40px 0px'}}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'center'}}>
                        <DirectoryTree
                            style={{background: '#f6f6f4', fontSize: '14px', float: 'center', textAlign: 'left'}}
                            showIcon
                            selectable={false}
                            switcherIcon={<DownOutlined/>}
                            defaultExpandedKeys={['0-0', '0-1', '0-2', '0-3']}
                            treeData={treeData.slice(0, 3)}

                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'center'}}>
                        <DirectoryTree
                            style={{background: '#f6f6f4', fontSize: '14px', float: 'center', textAlign: 'left'}}
                            showIcon
                            selectable={false}
                            switcherIcon={<DownOutlined/>}
                            defaultExpandedKeys={['0-3', '0-4', '0-5']}
                            treeData={treeData.slice(3, 6)}

                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const CTA = () => {
    return (
        <Row style={{minHeight: '400px', textAlign: 'center', backgroundColor: '#FFF'}} align={'middle'}
             justify={'space-around'}>
            <Col>
                <Space direction="vertical" size={'large'}>
                    <Title level={1}>
                        Start Learning Now!
                    </Title>
                    <AuthUserContext.Consumer>
                        {authUser =>
                            authUser ? (
                                <SignInButton route={ROUTES.COURSE} type={'primary'} text={'Getting Started'}/>
                            ) : (
                                <SignInButton route={ROUTES.SIGN_IN} type={'primary'} text={'Getting Started'}/>
                            )
                        }
                    </AuthUserContext.Consumer>
                </Space>
            </Col>
        </Row>
    )
}

const Home = () => {
        return (
            <>
                <Banner/>
                <Features/>
                {/*<Gif/>*/}
                {/*CTA*/}
                <Technologies/>
                <div style={{background: '#f6f6f4'}}>
                    <hr style={{
                        border: 0,
                        backgroundColor: 'rgb(224 214 206)',
                        display: 'block',
                        width: '100%',
                        height: '.0625rem',
                        margin: '1.25rem 0',
                        padding: 0
                    }}/>
                </div>
                <Curriculum/>
                <CTA/>
                <div style={{background: '#fff'}}>
                    <hr style={{
                        border: 0,
                        backgroundColor: 'rgb(224 214 206)',
                        display: 'block',
                        width: '100%',
                        height: '.0625rem',
                        margin: '1.25rem 0',
                        padding: 0
                    }}/>
                </div>
            </>
        )
    }
;


export default Home;