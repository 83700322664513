import React, { Component } from 'react';

import { withCognito } from '../Cognito';
import { Button, Form, Input, message} from "antd";

const INITIAL_STATE = {
    oldPassword: '',
    password: '',
    confirm: '',
    error: null
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

class PasswordChangeForm extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {...INITIAL_STATE};
    };


    onFinish = values => {
        const {oldPassword, password} = values;
        this.props.cognito.Auth.currentAuthenticatedUser()
            .then(user => {
                    this.props.cognito
                        .doPasswordUpdate(user, oldPassword, password)
                        .then(() => {
                            this.setState({...INITIAL_STATE});
                            // this.setState({success_messsage: 'Password changed succesfully.'})
                            // setTimeout(() => this.setState({success_messsage: ''}), 3000)
                            this.formRef.current.resetFields()
                            message.success("Successfully changed password.", 5)
                        })
                        .catch(error => {
                            this.setState({error});
                        })
                }
            ).catch(error => {
            this.setState({error});
        })
    };

    render() {

        const {error} = this.state;
        return (
                <Form {...layout} ref={this.formRef} onFinish={this.onFinish} initialValues={{...INITIAL_STATE}}>
                    <Form.Item
                        name="oldPassword"
                        label="Old Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your old password!',
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password!',
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your new password!',
                            },
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Reset My Password
                        </Button>
                    </Form.Item>

                    {error && <p>{error.message}</p>}
                </Form>
        );
    }
}


export default withCognito(PasswordChangeForm);