import {MinusCircleOutlined, CheckCircleFilled} from "@ant-design/icons";
import {Typography} from "antd";
import React from "react";

const {Text, Title} = Typography;

const treeData = [
    // {
    //     title: 'Introduction',
    //     key: 'Introduction',
    //     isLeaf: true,
    //     id: 'ok',
    //     icon: <CheckCircleFilled twoToneColor="#52c41a" style={{color: '#52c41a'}}/>,
    //     children: null
    // },
    {
        title: <Title level={4}>1. Introduction to the Cloud and Linux</Title>,
        key: '0-0',
        icon: <></>,
        selectable: false,
        children: [
            {
                title: <Text strong>EC2 and the Cloud</Text>,
                key: '0-0-0',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '1. AWS Setup',
                        key: '0-0-0-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '2. Starting, Stopping, and Connecting to EC2',
                        key: '0-0-0-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '3. Key Cloud Concepts',
                        key: '0-0-0-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '4. Basic Linux Commands',
                        key: '0-0-0-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '5. Linux Package Managers and Filesystem',
                        key: '0-0-0-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '6. Bash Scripting',
                        key: '0-0-0-5',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '7. AWS CLI and S3',
                        key: '0-0-0-6',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '8. Starting an AMI with Airflow and Sqlpad',
                        key: '0-0-0-7',
                        isLeaf: true,
                        icon: <></>,
                    },
                ]
            },
            {
                title: 'Project 1 - Data Pipeline with Airflow and Bash',
                key: '0-0-1',
                isLeaf: true,
                icon: <MinusCircleOutlined/>,
            },
        ],
    },
    {
        title: <Title level={4}>2. Relational Databases</Title>,
        key: '0-1',
        icon: <></>,
        selectable: false,
        children: [
            {
                title: 'Introduction to Relational Databases',
                key: '0-1-0',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '1. Tables, Columns, and Rows ',
                        key: '0-1-0-0',
                        icon: <></>,
                    },
                    {
                        title: '2. Relational Databases and Postgres',
                        key: '0-1-0-1',
                        icon: <></>,
                    },
                    {
                        title: '3. Launching a Postgres RDS Instance',
                        key: '0-1-0-2',
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'SQL Deep Dive',
                key: '0-1-1',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '4. Selecting Data',
                        key: '0-1-1-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '5. Using the Where Clause',
                        key: '0-1-1-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '6. Order By, Limit',
                        key: '0-1-1-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '7. Group By',
                        key: '0-1-1-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '8. Aggregate Functions',
                        key: '0-1-1-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '9. SQL Joins 1',
                        key: '0-1-1-5',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '10. SQL Joins 2',
                        key: '0-1-1-6',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '11. Common SQL Functions',
                        key: '0-1-1-7',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '12. Having Clause',
                        key: '0-1-1-8',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '13. CTE\'s and Nested Queries',
                        key: '0-1-1-9',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Data Modelling',
                key: '0-1-2',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '14. Normal Forms',
                        key: '0-1-2-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '15. Creating tables',
                        key: '0-1-2-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '16. Loading Data',
                        key: '0-1-2-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '17. Updating and Inserting Data',
                        key: '0-1-2-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '18. Denormalized Forms',
                        key: '0-1-2-4',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Project 2 - Postgres RDS Migration',
                key: '0-1-3-4',
                isLeaf: true,
                icon: <MinusCircleOutlined/>,
            }
        ],

    },
    {
        title: <Title level={4}>3. Data Warehouses</Title>,
        key: '0-2',
        icon: <></>,
        selectable: false,
        children: [
            {
                title: 'Data Warehouses and Snowflake',
                key: '0-2-0',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '1. Start a Snowflake Cluster',
                        key: '0-2-0-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '2. Loading Data into Snowflake',
                        key: '0-2-0-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '3. Clustering and Sort Keys',
                        key: '0-2-0-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '4. Virtual Warehouse Design',
                        key: '0-2-0-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '5. OLAP vs OLTP',
                        key: '0-2-0-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '6. Advanced SQL 1',
                        key: '0-2-0-5',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '7. Advanced SQL 2',
                        key: '0-2-0-6',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '8. Advanced SQL 3',
                        key: '0-2-0-7',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Modern ELT',
                key: '0-2-1',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '9. DDL vs DML',
                        key: '0-2-1-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '10. Drop Create Tables',
                        key: '0-2-1-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '11. Upserting Data',
                        key: '0-2-1-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '12. Views and Temp Tables',
                        key: '0-2-1-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '13. Dimensions and Fact Tables',
                        key: '0-2-1-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '14. Snowflake Schema',
                        key: '0-2-1-5',
                        isLeaf: true,
                        icon: <></>
                    },
                    {
                        title: '15. Star Schema',
                        key: '0-2-1-6',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Project 3 - ETL to ELT Migration',
                key: '0-2-2',
                isLeaf: true,
                selectable: false,
                icon: <MinusCircleOutlined/>
            }
        ],
    },
    {
        title: <Title level={4}>4. Python and Airflow</Title>,
        key: '0-3',
        icon: <></>,
        selectable: false,
        children: [
            {
                title: 'Python Foundation',
                key: '0-3-0',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '1. Introduction to Python',
                        key: '0-3-0-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '2. Python Data Types',
                        key: '0-3-0-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '3. Lists and Dictionaries',
                        key: '0-3-0-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '4. Conditional Expressions',
                        key: '0-3-0-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '5. For and While Loops',
                        key: '0-3-0-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '6. String Methods',
                        key: '0-3-0-5',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '7. Functions',
                        key: '0-3-0-6',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '8. Classes and OOP 1',
                        key: '0-3-0-7',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '9. Classes and OOP 2',
                        key: '0-3-0-8',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '10. Pip and Packages',
                        key: '0-3-0-9',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '11. Scripting',
                        key: '0-3-0-10',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Python for ETL',
                key: '0-3-1',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '12. Common Packages',
                        key: '0-3-1-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '13. Jupyter Notebooks',
                        key: '0-3-1-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '14. SQL Connectors',
                        key: '0-3-1-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '15. Executing SQL',
                        key: '0-3-1-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '16. Data Cleaning Operations 1',
                        key: '0-3-1-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '17. Data Cleaning Operations 2',
                        key: '0-3-1-5',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]

            },
            {
                title: 'Airflow',
                key: '0-3-2',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '18. Airflow and DAGs',
                        key: '0-3-2-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '19. Airflow Tasks 1',
                        key: '0-3-2-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '20. Airflow Tasks 2',
                        key: '0-3-2-2',
                        isLeaf: true,
                        icon: <></>,
                    },

                    {
                        title: '21. Advanced Airflow',
                        key: '0-3-2-3',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Project 4',
                key: '0-3-3',
                isLeaf: true,
                icon: <MinusCircleOutlined/>
            }
        ],
    },
    {
        title: <Title level={4}>5. Big Data</Title>,
        key: '0-4',
        icon: <></>,
        selectable: false,
        children: [
            {
                title: 'Introduction to Big Data',
                key: '0-4-0',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '1. Starting an EMR Cluster',
                        key: '0-4-0-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '2. Hive on EMR 1',
                        key: '0-4-0-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '3. Hive on EMR 2',
                        key: '0-4-0-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '4. Spark 1',
                        key: '0-4-0-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '5. Spark 2',
                        key: '0-4-0-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '6. Spark 3',
                        key: '0-4-0-5',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '7. Scaling EMR Jobs',
                        key: '0-4-0-6',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Serverless Big Data',
                key: '0-4-1',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '8. AWS Athena',
                        key: '0-4-1-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '9. AWS Glue 1',
                        key: '0-4-1-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '10. AWS Glue 2',
                        key: '0-4-1-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '11. Overview of Big Data Landscape',
                        key: '0-4-1-3',
                        isLeaf: true,
                        icon: <></>,
                    }
                ]
            },
            {
                title: 'Project 5 - Cleaning House with Airflow',
                key: '0-4-2',
                isLeaf: true,
                icon: <MinusCircleOutlined/>
            }
        ],
    },
    {
        title: <Title level={4}>6. IaC and Kafka</Title>,
        key: '0-5',
        icon: <></>,
        selectable: false,
        children: [
            {
                title: 'Kafka',
                key: '0-5-0',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '1. Introduction to Streaming',
                        key: '0-5-0-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '2. Brokers and Topics',
                        key: '0-5-0-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '3. Setting up a Kafka Cluster',
                        key: '0-5-0-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '4. Producing to a Kafka Topic',
                        key: '0-5-0-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '5. Consuming from Kafka',
                        key: '0-5-0-4',
                        isLeaf: true,
                        icon: <></>,
                    },
                ],

            },
            {
                title: 'Terraform, Ansible',
                key: '0-5-1',
                icon: <></>,
                selectable: false,
                children: [
                    {
                        title: '6. Launching EC2 with Terraform',
                        key: '0-5-1-0',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '7. Other Provisioning',
                        key: '0-5-1-1',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '8. Configuring EC2 with Ansible 1',
                        key: '0-5-1-2',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '9. Configuring EC2 with Ansible 2',
                        key: '0-5-1-3',
                        isLeaf: true,
                        icon: <></>,
                    },
                    {
                        title: '10. Advanced Ansible',
                        key: '0-5-1-4',
                        isLeaf: true,
                        icon: <></>,
                    },

                ]
            },
            {
                title: 'Project 6',
                key: '0-5-0-8',
                isLeaf: true,
                icon: <MinusCircleOutlined/>,
            }
        ]
    }
];

export default treeData