import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import * as ROUTES from '../../constants/routes';
import {Hub} from "aws-amplify";

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            Hub.listen('auth', (data) => {
                switch (data.payload.event) {
                    case 'signIn':
                        this.props.cognito.getCurrentAuthenticatedUser(authUser =>
                            authUser => {
                                if (!condition(authUser)) {
                                    this.props.history.push(ROUTES.SIGN_IN);
                                }
                            });
                        break;
                    case 'signOut':
                        this.props.history.push(ROUTES.SIGN_IN);
                        break;
                    default:
                        break;
                }
            })
        }

        componentWillUnmount() {
            Hub.remove('auth', null)
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        condition(authUser) ? <Component {...this.props} /> : null
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(
        withRouter,
    )(WithAuthorization);
};

export default withAuthorization;