import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import Cognito, {CognitoContext} from './components/Cognito';
import Amplify, {Auth} from 'aws-amplify';
import * as ROLES from "./constants/roles";

const {SplitFactory} = require('@splitsoftware/splitio-react');

const SplitSDKConfig = {
    core: {
        authorizationKey: process.env.REACT_APP_SPLIT_API_KEY,
        key: 'key'
    }
};

Amplify.configure({
    Auth: {
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        clientMetadata: {myCustomKey: 'myCustomValue'},
        oauth: {
            domain: 'dropschema.auth.us-east-1.amazoncognito.com',
            scope: [
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: 'https://localhost:3000/home',
            redirectSignOut: 'https://localhost:3000',
            responseType: "token"
        },
    },
    API: {
        graphql_endpoint: process.env.REACT_APP_HASURA_ENDPOINT,
        graphql_headers: async () => {
            return (
                Auth.currentSession().then(session => {
                        console.log(session.getIdToken().getJwtToken())
                        const roles = session.getAccessToken().decodePayload()['cognito:groups']

                        var hasura_role = ROLES.ANONYMOUS
                        if (roles.includes(ROLES.ADMIN)) {
                            hasura_role = ROLES.ADMIN
                        } else if (roles.includes(ROLES.PAID_USER)) {
                            hasura_role = ROLES.PAID_USER
                        } else if (roles.includes(ROLES.USER)) {
                            hasura_role = ROLES.USER
                        } else {
                            hasura_role = ROLES.ANONYMOUS
                        }
                        const header = {
                            'Authorization': 'Bearer ' + session.getIdToken().getJwtToken(),
                            'X-Hasura-Role': hasura_role,
                        }
                        console.log(header)
                        return header
                    }
                )
            )
        }
    }
});


ReactDOM.render(
    <CognitoContext.Provider value={new Cognito()}>
        <SplitFactory config={SplitSDKConfig}>
            <App/>
        </SplitFactory>
    </CognitoContext.Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
