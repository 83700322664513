
export const getLessonContentTree = /* GraphQL */ `

query {
    dropschema_lesson_content(
        where: {is_visible_landing_page: {_eq: true}}
) {
        content_id
        name
        parent_id
    }
}

`;
