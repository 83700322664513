import React, { Component } from 'react';
import { SignUpLink } from "./signup";
import {Link, withRouter} from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes"
import { withCognito } from "../Cognito";
import { PasswordForgetLink } from './passwordforget';
import {Row, Col, Form, Input, Button, message} from "antd";
import { Typography } from "antd";
import {SplitTreatments} from '@splitsoftware/splitio-react';

const {Paragraph} = Typography;

const SignInPage = () => (
    <div>
        <Row type="flex" justify="center" align="middle" style={{minHeight: '80vh'}}>
            <Col xs={{span: 24, offset: 0}} sm={{span: 24, offset: 0}} md={{span: 22, offset: 0}}
                 lg={{span: 12, offset: 0}} xl={{span: 8, offset: 0}} xxl={{span: 8, offset: 0}}>
        <SigninForm/>
        <PasswordForgetLink/>
        <Paragraph>
            <SignUpLink/>
        </Paragraph>
            </Col>
        </Row>
    </div>
);

const INITIAL_STATE = {
    email:'',
    password: '',
    error: null
};

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

class SigninFormBase extends Component {
    constructor(props) {
        super(props);
    this.state = {...INITIAL_STATE}
    }
    onFinish = values => {
        const {email, password} = values
        this.props.cognito.doSignIn(email, password)
            .then(() => {
                this.setState({...INITIAL_STATE})
                this.props.history.push(ROUTES.COURSE)
            })
            .catch(error => {
                this.setState({error});
            });
    };

    onChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    render (){
        const {error} = this.state;

        return (
            <Form {...layout} onFinish={this.onFinish}>
                <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[
                            {
                                required: true,
                                message: 'Required Field',
                            },
                        ]}
                    >
                <Input/>
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Log In
                    </Button>
                </Form.Item>
                {error && <p>{error.message}</p>}
            </Form>
        );
    }
}

const SigninForm = compose(
    withRouter,
    withCognito,
)(SigninFormBase);

const SignInButton = ({route, type, text}) => {
    const error = () => {
        message.error('We are still building course content. Come back soon!');
    };
    const renderContent = (treatmentWithConfig, linkRoute) => {
        const {treatment, config} = treatmentWithConfig;
        if (treatment === 'on') {
            return (<Button type={type} size={'large'}><Link to={linkRoute}>{text}</Link></Button>);
        }
        return (<Button type={type} onClick={error} size={'large'}>{text}</Button>);
    }


    return (
        <SplitTreatments names={['login-signup']}>
            {({treatments, isReady}) => {
                return isReady ?
                    renderContent(treatments['login-signup'], route) : // Use the treatments and configs.
                    null; // Render a spinner if the SDK is not ready yet. You can do what you want with this boolean.
            }}
        </SplitTreatments>
    )
}

export {SigninFormBase, SignInButton};
export default SignInPage;