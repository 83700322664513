import React from 'react';

import { withCognito } from '../Cognito';
import { Button } from "antd";

const SignOutButton = ({ cognito }) => (
    <Button type="primary" htmlType="submit" onClick={cognito.doSignOut}>
        Log Out
    </Button>
);

export default withCognito(SignOutButton);