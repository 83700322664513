import React, {useState, useEffect} from 'react';
import {Col, Row, Affix, Typography, Card, Button, Steps, Input} from 'antd';
import {withAuthorization} from '../Session';
import 'antd/dist/antd.css';
import {DownOutlined, CheckOutlined} from '@ant-design/icons';
import './index.css'
import DirectoryTree from "antd/es/tree/DirectoryTree";
import ReactMarkdown from 'react-markdown'
import textpath from './example.md'
import treeData from "./treeData";
import JsxParser from 'react-jsx-parser'


const {Text, Title, Link} = Typography;

const {Step} = Steps;

function LessonNav() {
    const onSelect = (keys, event) => {
        console.log('Trigger Select', keys, event);
    };

    const onExpand = () => {
        console.log('Trigger Expand');
    };

    return (
        <Card
            style={{boxShadow: 'rgb(126, 127, 130) 0px 2px 10px'}}
            bodyStyle={{padding: '16px 2px'}} title={"Lessons"}>
            <DirectoryTree
                style={{fontSize: '16px', float: 'center'}}
                showIcon
                switcherIcon={<DownOutlined/>}
                defaultExpandAll
                onSelect={onSelect}
                onExpand={onExpand}
                treeData={treeData}
            />
        </Card>
    )
}


const renderers = (callback) => {
    return {
        code: ({language, value}) => {
            if (language === 'jsx') {

                return <JsxParser bindings={{
                    foo: 'bar',
                    myEventHandler: callback,
                }}
                                  blacklistedAttrs={[]}
                                  jsx={value} components={{Input}}/>
            }
            return null
        }
    }
}


function CourseContent () {
    const [text, setText] = useState(null)
    const [addText, setAddText] = useState('')
    useEffect(() => {
        fetch(textpath).then((response) => response.text()).then((newtext) => {
            setText(newtext + addText)
        })
    });

    const addTextFunc = event => {
        const {value} = event.target
        setAddText(addText + value)
    }

    return (
        <Card title={<Title>Introduction</Title>} style={{textAlign: 'left', fontSize: '16px'}}>
            <ReactMarkdown source={text} escapeHtml={false} renderers={renderers(addTextFunc)}/>
        </Card>
    )
}

const leftCol = {
    xs: {span: 4, offset: 0},
    sm: {span: 4, offset: 0},
    md: {span: 5, offset: 0},
    lg: {span: 6, offset: 0},
    xl: {span: 6, offset: 0}
}
const midCol = {
    xs: {span: 13, offset: 6},
    sm: {span: 13, offset: 6},
    md: {span: 13, offset: 0},
    lg: {span: 13, offset: 0},
    xl: {span: 13, offset: 0}
}
const rightCol = {span: 4}

function CourseSteps  () {

    return (
        <Affix offsetBottom={220}>
            <Card title={<Link href="https://ant.design" target="_blank">Project 1</Link>}
                  style={{boxShadow: 'rgb(126, 127, 130) 0px 2px 10px'}}>
                <Steps direction="vertical" current={0}>
                    <Step title="Setup AWS"/>
                    <Step title="Launch and Connect to EC2"/>
                    <Step title="Edit Pipeline Code"/>
                    <Step title="Submit"/>
                </Steps>
            </Card>
        </Affix>
    )
}

function CoursePage() {
    const [markCompleteButtonType, setMarkCompleteButtonType] = useState('')
    // const [text, setText] = useState(null)

    const onButtonNotAffixed = (affix) => {
        affix ? (setMarkCompleteButtonType('')) :
            (setMarkCompleteButtonType('primary'))
    }
    return (
        <>
            <Row style={{padding: '20px 0px 0px 0px'}} justify="space-between">

                <Col xs={leftCol.xs} sm={leftCol.sm} md={leftCol.md} lg={leftCol.lg} xl={leftCol.xl}>
                    <LessonNav/>
                </Col>

                <Col xs={midCol.xs} sm={midCol.sm} md={midCol.md} lg={midCol.lg} xl={midCol.xl}>
                    <Row>
                        <CourseContent/>
                    </Row>
                    <Row align={'middle'} style={{minHeight: '80px'}}>
                        <Affix offsetBottom={20} onChange={affixed => onButtonNotAffixed(affixed)}>
                            <Button type={markCompleteButtonType} shape="round" icon={<CheckOutlined/>}
                                    size={'large'}>
                                Mark Complete
                            </Button>
                            <Button>Previous Step</Button>
                        </Affix>
                    </Row>
                </Col>
                <Col span={rightCol.span}>
                    <CourseSteps/>
                </Col>
            </Row>
            {/*<Row justify="center">*/}
            {/*    <Col span={6} offset={2}>*/}
            {/*        <Affix offsetBottom={20} onChange={affixed => onButtonNotAffixed(affixed)}>*/}
            {/*            <Button type={markCompleteButtonType} shape="round" icon={<CheckOutlined/>}*/}
            {/*                    size={'large'}>*/}
            {/*                Mark Complete*/}
            {/*            </Button>*/}
            {/*            <Button>Previous Step</Button>*/}
            {/*        </Affix>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row>
                <Card title={"Hello"}>
                    <h2>Other Resource</h2>
                    <Text>Hello</Text>
                    <Text>Hello</Text>
                    <Text>Hello</Text>
                    <Text>Hello</Text>
                    <Text>Hello</Text>
                </Card>
            </Row>
            <Row><Col>
                <h2>content</h2>
                <p>content</p>
                <h2>content</h2>
                <p>content</p>
                <h2>content</h2>
                <p>content</p>
                <h2>content</h2>
                <p>content</p>
                <h2>content</h2>
                <p>content</p>
                <h2>content</h2>
                <p>content</p>
                <h2>content</h2>
                <p>content</p>
                <h2>content</h2>
                <p>content</p>
            </Col>
            </Row>
        </>
    )
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(CoursePage);