import React from 'react';
import { Link } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import { Layout, Menu, Col, Row } from 'antd'
import { SignOutButton } from '../Account';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import SubMenu from "antd/es/menu/SubMenu";
import { MenuOutlined } from '@ant-design/icons';
import {SignInButton} from '../Account/signin'

const { Header } = Layout;

const NavigationAuth = ({ authUser }) => (
    <Menu  mode="horizontal" style={{float:'center', background: '0 0', borderBottom: '0'}} defaultSelectedKeys={['Home']}>
        <Menu.Item key="Course" ><Link to={ROUTES.COURSE}>Course</Link></Menu.Item>
        <SubMenu key="Account Sub" title="Account">
            <Menu.Item key="Settings"><Link to={ROUTES.ACCOUNT}>Account</Link></Menu.Item>
            { authUser.getSignInUserSession().getAccessToken().decodePayload()['cognito:groups'].includes(ROLES.ADMIN) && (
                <Menu.Item key="Admin"><Link to={ROUTES.ADMIN}>Admin</Link></Menu.Item>
            )}
            <Menu.Item key="Log Out"><SignOutButton/></Menu.Item>
        </SubMenu>
    </Menu>
);


const NavigationNonAuth = () => (
    <Menu overflowedIndicator={<MenuOutlined/>} mode="horizontal" style={{float:'center', background: '0 0',  borderBottom: '0'}} defaultSelectedKeys={['Home']} >
        <Menu.Item key="Log In" ><SignInButton route={ROUTES.SIGN_IN} type={'link'} text={'Log In'}/></Menu.Item>
        <Menu.Item key="Sign Up" ><SignInButton route={ROUTES.SIGN_UP} type={'link'} text={'Sign Up'}/></Menu.Item>
    </Menu>
);


const Navigation = ({header_style}) => (

    <Header style={{...header_style}} >
        <Row justify={'right'}>
            <Col xs={{span: 12, offset: 4}} sm={{span: 6, offset: 7}} md={{span: 6, offset: 4}} lg={{span: 4, offset: 1}} xl={{span: 3, offset: 1}}>
                <Link to={ROUTES.HOME} ><img style={{width: '200px', height: '25px'}} alt={"logo"} src={"https://dropschema.s3.amazonaws.com/logo_with_text.svg"}/></Link>
            </Col>

            <Col xs={{span: 4, offset: 4}} sm={{span: 8, offset: 3}} md={{span: 8, offset: 4}} lg={{span: 6, offset: 10}} xl={{span: 6, offset: 14}}>
                <AuthUserContext.Consumer>
                    {authUser =>
                        authUser ? (
                            <NavigationAuth authUser={authUser} />
                        ) : (
                            <NavigationNonAuth />
                        )
                    }
                </AuthUserContext.Consumer>
            </Col>
        </Row>
    </Header>
);

export default Navigation;