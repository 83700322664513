import { Auth } from 'aws-amplify';

class Cognito {
    constructor() {
        this.Auth = Auth;
    }

    doSignUp = (email, password, attributes) =>
        this.Auth.signUp({
            // username=email in AWS cognito, we will save the actually inputted username to
            username: email,
            password: password,
            attributes: attributes
        })

    doSignIn = (email, password) =>
        this.Auth.signIn(email, password)

    doSignOut = (email, password, attributes) =>
        this.Auth.signOut()

    doPasswordUpdate = (user, oldpassword, password) =>
        this.Auth.changePassword(user, oldpassword, password)

    getCurrentSession = () =>
        this.Auth.currentSession()

    getCurrentAuthenticatedUser = () =>
        this.Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })



}

export default Cognito;
