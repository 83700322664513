import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import HomePage from './Home';
import {SignUpPage, SignInPage, AccountPage, AdminPage, PasswordForgetForm} from './Account';
import CoursePage from './Course';
import Navigation from './Navigation';
import * as ROUTES from '../constants/routes';
import {withAuthentication} from './Session';
import {Layout} from "antd";
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import ScrollToTop from "./Navigation/ScrollToTop";
const {Content} = Layout;

const HEADER_STYLE_FIXED = {
    position: 'fixed', zIndex: 1, width: '100%', background: '#ffffff', lineHeight: '62px',
    boxShadow: "0px 2px 10px #7e7f82"
}

const HEADER_STYLE_NOT_FIXED = {
    zIndex: 1, width: '100%', lineHeight: '62px',
    position: 'absolute', top: '0', left: '0', right: '0', backgroundColor: '#ffffff00'
}



const App = () => (
    <Router>
        <ScrollToTop/>
        <Layout>
            <Switch>
                <Route exact path={ROUTES.HOME}>
                    <Navigation header_style={HEADER_STYLE_NOT_FIXED}/>
                    <HomePage/>
                </Route>
                <Route exact path={['/*']}>
                    <Navigation header_style={HEADER_STYLE_FIXED}/>
                    <Content className="site-layout" style={{padding: '5px 20px 0px 20px', marginTop: 72, zIndex: 0}}>
                        <Route exact path={ROUTES.SIGN_UP} component={SignUpPage}/>
                        <Route exact path={ROUTES.SIGN_IN} component={SignInPage}/>
                        <Route exact path={ROUTES.COURSE} component={CoursePage}/>
                        <Route exact path={ROUTES.ACCOUNT} component={AccountPage}/>
                        <Route exact path={ROUTES.ADMIN} component={AdminPage}/>
                        <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetForm}/>
                    </Content>
                </Route>
            </Switch>
            <Footer
                theme={'light'}
                backgroundColor={'#FFFFFF'}
                columns={[
                    {
                        icon: (
                            <img alt='support-symbol' src="https://gw.alipayobjects.com/zos/rmsportal/XuVpGqBFxXplzvLjJBZB.svg"/>
                        ),
                        title: 'Support',
                        items: [
                            {
                                title: 'Contact Us',
                                url: 'https://dropschema.freshdesk.com',
                                openExternal: true,
                            }
                        ]
                    },
                ]}
                bottom="dropschema.io"
            />
        </Layout>
    </Router>
);

export default withAuthentication(App);