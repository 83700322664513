import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {Button, Form, Input, Col, Row} from 'antd';
import { withCognito } from '../Cognito';
import * as ROUTES from '../../constants/routes';
import {Typography} from "antd";
import {Auth} from 'aws-amplify';
import reactComponentDebounce from 'react-component-debounce';

const {Text} = Typography;
const ReactMarkdown = require('react-markdown/with-html')

const SignUpPage = () => (
    <div>
        <SignUpForm />
    </div>
);

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const INITIAL_STATE = {
    username: '',
    email: '',
    password: '',
    confirm: '',
    error: null,
};

const Formy = reactComponentDebounce(3000)(Form)
class SignUpFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.container = React.createRef();
    }

    onFinish = values => {
        const { username, email, password} = values;
        this.props.cognito.doSignUp(email, password, {
                email: email,
                nickname: username
            }
        )
            .then((res) => {
            this.setState({ ...INITIAL_STATE });
            if (res.userConfirmed){
                console.log('SignUp Success!');

                this.props.cognito.doSignIn(email, password).then((res)=>{console.log(res)});
                clearTimeout()
                this.props.history.push(ROUTES.COURSE);
            }
        })
            .catch(error => {
                console.log(error);
                if (error.code === 'UserLambdaValidationException' && error.message==="PreSignUp failed with error Username already exists."){
                    error.message = 'Username already exists.'
                }
            this.setState({ error });
        })
    };



    render() {
        const {error} = this.state;
        return (
            <>
            <Row type="flex" justify="center" align="middle" style={{minHeight: '80vh'}}>
            <Col xs={{span: 24, offset: 0}} sm={{span: 18, offset: 0}} md={{span: 12, offset: 0}}
                 lg={{span: 12, offset: 0}} xl={{span: 10, offset: 0}} xxl={{span: 10, offset: 0}}>
                <h2>Sign Up</h2>
                <Formy {...layout} onFinish={this.onFinish}>
                <Form.Item
                    name="username"
                    label="Username"
                    rules={[
                        {
                            required: true,
                            message: 'Required Field',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                        {
                            required: true,
                            message: 'Required Field',
                        },
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                    ]}
                >
                <Input/>
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        })
                    ]}
                >
                <Input.Password/>
                </Form.Item>
                <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Sign Up
                </Button>
                <button onClick={() => Auth.federatedSignIn({provider: 'Google' })}>Open Google</button>
                </Form.Item>

                {error && <p>{error.message}</p>}
            </Formy>
            </Col>
            </Row>
            <Row>
                <Col span={18}>
                <ReactMarkdown
                    source='# Hello'
                    escapeHtml={false}
                />
                </Col>
            </Row>
        </>
        );
    }
}

const SignUpLink = () => (
    <>
    <Text>
        Don't have an account? </Text>
    <Text strong> <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </Text>
        </>
);

const SignUpForm = withRouter(withCognito(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };