import React from 'react';
import AuthUserContext from './context';
import { withCognito } from '../Cognito';
import {Hub} from 'aws-amplify';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = {

            }
            this.props.cognito.getCurrentAuthenticatedUser().then(user=>{
                this.setState({authUser: user})
            }
            )
                .catch(error => console.log)
        }

        componentDidMount() {
            Hub.listen('auth', (data) => {
                switch (data.payload.event) {
                    case 'signIn':
                        this.props.cognito.getCurrentAuthenticatedUser()
                            .then(user => {
                                this.setState({ authUser: user });
                            })
                            .catch(error => console.log(error))

                        break;
                    case 'signOut':
                        this.setState({ authUser: null });
                        break;
                    default:
                        break;
                }
            })
        }

        componentWillUnmount() {
            Hub.remove('auth', null)
        }

        render() {
            return (
                this.state === null ? <p>Loading...</p>:
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return withCognito(WithAuthentication);
};

export default withAuthentication;